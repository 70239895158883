<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Announcement</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            icon-left="plus"
            class="is-command"
            @click="openModalAnnouncement()"
            v-if="currentUser.isAdmin"
          >
            New
          </b-button>
        </div>
      </div>
    </template>
    <!-- Left Table -->
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-4 announcement-table-card">
          <r-card>
            <b-table
              :data="getAnnouncementIndex"
              :loading="isAnnouncementLoading"
              ref:table
              hoverable
              backend-sorting
              :default-sort-direction="defaultSortOrder"
              :default-sort="[sortField, sortOrder]"
              class="employment-directory-table table-log-activity-personal general-table"
              height="760px"
              @sort="onSort"
              :sticky-header="stickyHeaders"
              :selected.sync="selectedAnnouncement"
            >
              <template>
                <b-table-column
                  field="title"
                  label="Title"
                  v-slot="props"
                  sortable
                >
                  <span class="is-capitalize">
                    {{ textSubString(props.row.title, 20, '...') }}
                  </span>
                </b-table-column>
                <b-table-column
                  field="updated_at"
                  label="Date"
                  v-slot="props"
                  sortable
                >
                  <span class="is-capitalize">
                    {{ formatDate(props.row.updatedAt) }}
                  </span>
                </b-table-column>
                <b-table-column
                  field="content"
                  label="Content"
                  v-slot="props"
                  sortable
                >
                  <span class="is-capitalize">
                    {{ textSubString(props.row.content, 20, '...') }}
                  </span>
                </b-table-column>
              </template>
              <template slot="empty">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </template>
            </b-table>
          </r-card>
        </div>
        <div
          class="column is-8 announcement-table-card"
          v-if="selectedAnnouncement"
        >
          <r-card>
            <b-button
              type="is-command"
              icon-left="pencil-outline"
              @click.native="openModalAnnouncement(selectedAnnouncement)"
              v-if="currentUser.isAdmin"
            >
              Edit
            </b-button>
            <b-button
              type="is-command"
              icon-left="trash-can-outline"
              v-if="currentUser.isAdmin"
              @click.native="deleteAnnouncementDetail(selectedAnnouncement)"
            >
              Delete
            </b-button>
            <div class="level mb-0">
              <div class="level-left announcement-title">
                <div class="column">
                  {{ dataDetail.title }}
                </div>
              </div>
              <div class="level-right announcement-normal-size">
                <div class="column">{{ formatDate(dataDetail.updatedAt) }}</div>
              </div>
            </div>
            <div class="columns is-multiline mt-0">
              <div class="column is-1 ml-3 mr-0" id="announcement-id">
                <div class="announcement-profile-picture-container">
                  <img
                    v-if="
                      dataDetail.createdBy &&
                      dataDetail.createdBy.profilePictureUrl !== null
                    "
                    :src="dataDetail.createdBy.profilePictureUrl"
                  />
                  <img v-else src="/images/default-profile-picture-male.png" />
                </div>
              </div>
              <div class="column announcement-normal-size ml-0 pl-0">
                {{ dataDetail.createdBy ? dataDetail.createdBy.fullName : '' }}
              </div>
            </div>
            <div class="column is-12 mt-0 mb-0 pt-0">
              <hr class="black-divider" />
            </div>
            <div
              class="column is-12 announcement-picture-container ml-3"
              v-if="dataDetail.files && dataDetail.files.length !== 0"
            >
              <img :src="getImageURL(dataDetail.files[0].url)" />
            </div>
            <div class="column is-12 has-text-justified">
              {{ dataDetail.content }}
            </div>
          </r-card>
        </div>
        <div class="column is-8 announcement-table-card" v-else>
          <img
            src="/images/RISE-Gray-Transparent.png"
            class="announcement-no-data-pic-container"
          />
          <div class="column is-12 has-text-centered announcement-no-data-gray">
            No Preview Available
          </div>
        </div>
      </div>
      <r-page-modal
        :prompt-on-leave="false"
        prompt-message="Are you sure?"
        v-if="isModalAnnouncementOpen"
        @hide="closeModalAnnouncement"
        :is-modal="true"
        key="modalKey"
        class="modal-allowed-capacity"
        remove-scrolling
      >
        <template #page-content>
          <h3 class="modal-title has-text-weight-black">
            {{ isEditing ? 'Edit Announcement' : 'Create Announcement' }}
            <span class="office-pointer-click is-pulled-right">
              <b-icon
                icon="close"
                size="is-small"
                @click.native="closeModalAnnouncement"
              ></b-icon>
            </span>
          </h3>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(submit)"
              class="is-labe-14px-normal form-timeoff-type"
            >
              <div class="columns is-multiline">
                <div class="column is-12">
                  <ValidationProvider
                    name="subject"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Subject"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        placeholder="Enter Subject"
                        v-model="data.title"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="description"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Description"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        placeholder="Enter description"
                        type="textarea"
                        class="hover"
                        v-model="data.content"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-6">
                  <ValidationProvider
                    name="date"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Date"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-datepicker
                        placeholder="Enter Date"
                        class="attendance-datepicker"
                        icon-right="calendar"
                        v-model="data.startPublishedAt"
                      ></b-datepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-6">
                  <ValidationProvider
                    name="until"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Until"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-datepicker
                        placeholder="Enter Date"
                        class="attendance-datepicker"
                        icon-right="calendar"
                        v-model="data.endPublishedAt"
                      ></b-datepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12" v-if="data.images == null">
                  <b-field label="Upload Image">
                    <b-upload
                      accept="image/*"
                      drag-drop
                      expanded
                      v-model="data.images"
                      @input="onFileChange"
                    >
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon icon="image" size="is-large"></b-icon>
                          </p>
                          <p>
                            Drag image here or
                            <span class="has-red-text">Browse</span>
                          </p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>
                </div>
                <div class="column is-12" v-if="data.images != null">
                  <b-field label="Upload File">
                    <div
                      class="is-12 office-picture-container"
                      v-if="data.images != null"
                    >
                      <img v-if="url" :src="url" />
                      <img :src="getImageURL(data.images)" v-else />
                      <b-icon
                        class="delete is-small office-button-container office-pointer-click"
                        @click.native="deleteDropFile()"
                      ></b-icon>
                    </div>
                  </b-field>
                </div>
                <div class="column is-12">
                  <b-button
                    expanded
                    native-type="submit"
                    type="is-primary"
                    :loading="isSubmitting"
                  >
                    {{ isEditing ? 'Update' : 'Submit' }}
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </r-page-modal>
    </template>
  </r-page>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import { showToast } from '../../services/util'
export default {
  data() {
    return {
      isModalAnnouncementOpen: false,
      data: [],
      dataDetail: {},
      isAnnouncementLoading: false,
      isAnnouncementDetailLoading: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      stickyHeaders: true,
      url: null,
      isSubmitting: false,
      isEditing: false,
      selectedAnnouncement: null,
      selectedDate: new Date(),
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      getAnnouncementIndex: 'announcement/getAnnouncementIndex',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadAnnouncement: 'announcement/fetchAnnouncementIndex',
      actionLoadAnnouncementPublished:
        'announcement/fetchAnnouncementPublished',
      actionLoadAnnouncementDetail: 'announcement/fetchAnnouncementDetail',
      saveAnnouncement: 'announcement/saveAnnouncement',
      updateAnnouncement: 'announcement/updateAnnouncement',
      deleteAnnouncementIndex: 'announcement/deleteAnnouncement',
    }),

    ...mapMutations({
      setAnnouncement: 'announcement/setAnnouncementIndex',
    }),

    openModalAnnouncement(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          title: row.title,
          content: row.content,
          startPublishedAt: new Date(row.startPublishedAt),
          endPublishedAt: new Date(row.endPublishedAt),
          images: row.files ? row.files[0]?.url : null,
        }
      }
      this.isModalAnnouncementOpen = true
    },

    closeModalAnnouncement() {
      this.resetForm()
      this.isModalAnnouncementOpen = false
      this.isEditing = false
    },

    async loadAnnouncementIndex() {
      this.isAnnouncementLoading = true

      if (this.currentUser?.isAdmin) {
        try {
          const response = await this.actionLoadAnnouncement({
            perPage: this.perPage,
            page: ++this.page,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
          })
          this.data = response.data.data
          this.total = response.data.meta.total
          this.lastPage = response.data.meta.lastPage
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          const response = await this.actionLoadAnnouncementPublished({
            perPage: this.perPage,
            page: ++this.page,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
          })
          this.data = response.data.data
          this.total = response.data.meta.total
          this.lastPage = response.data.meta.lastPage
        } catch (e) {
          console.log(e)
        }
      }

      this.isAnnouncementLoading = false
    },

    async loadAnnouncementDetail(selectedAnnouncement) {
      this.isAnnouncementDetailLoading = true
      try {
        const response = await this.actionLoadAnnouncementDetail({
          selectedAnnouncement: selectedAnnouncement,
        })
        this.dataDetail = response.data.data
      } catch (e) {
        console.log(e)
      }
      this.isAnnouncementDetailLoading = false
    },

    resetForm() {
      this.selectedAnnouncement = null
      this.data = {
        title: null,
        content: null,
        createdAt: null,
        updatedAt: null,
        startPublishedAt: null,
        endPublishedAt: null,
        images: null,
      }
    },

    // Submit data
    async submit() {
      const form = new FormData()
      form.append('title', this.data.title)
      form.append('content', this.data.content)
      form.append(
        'start_published_at',
        moment(this.data.startPublishedAt).format('YYYY-MM-DD')
      )
      form.append(
        'end_published_at',
        moment(this.data.endPublishedAt).format('YYYY-MM-DD')
      )

      this.isSubmitting = true
      if (!this.isEditing) {
        try {
          if (this.data.images) {
            form.append('images[]', this.data.images)
          }
          await this.saveAnnouncement(form)
          showToast('Announcement added!', 'is-success', 'is-top')
        } catch (e) {
          showToast(e.message, 'is-danger', 'is-top')
        }
      } else {
        try {
          if (
            this.data.images !== this.selectedAnnouncement.files?.[0]?.url &&
            this.data.images !== null
          ) {
            form.append('images[]', this.data.images)
          }
          const data = { id: this.data.id, form }
          await this.updateAnnouncement(data)
          showToast('Announcement updated!', 'is-success', 'is-top')
        } catch (e) {
          showToast(e.message, 'is-danger', 'is-top')
        }
      }
      this.isSubmitting = false
      this.closeModalAnnouncement()
    },

    // For Image
    getImageURL(item) {
      return item ? process.env.VUE_APP_API_URL + '/' + item : null
    },

    deleteDropFile() {
      this.data.images = null
      this.url = null
    },

    onFileChange(e) {
      const file = e
      this.url = URL.createObjectURL(file)
      URL.revokeObjectURL(file)
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadAnnouncementIndex()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setAnnouncement([])
      await this.loadAnnouncementIndex()
    },

    async deleteAnnouncementDetail(selected) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action`,
      })

      try {
        if (res && res.isConfirmed) {
          await this.deleteAnnouncementIndex(selected.id)
          this.$swal({
            icon: 'success',
            titleText: 'Announcement deleted!',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.resetForm()
        }
      } catch (e) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed to delete announcement. Please try again!',
          text: e,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMM YYYY | HH:mm')
      }
    },
  },

  async mounted() {
    this.setAnnouncement([])
    await this.loadAnnouncementIndex()
  },

  watch: {
    selectedAnnouncement(val) {
      if (val) {
        this.loadAnnouncementDetail(val.id)
      }
    },
  },
}
</script>
